'use strict';


var lazyload = require('jquery-lazy'),
    lazyloadPlugin = require('../../../node_modules/jquery-lazy/jquery.lazy.plugins'),
    imgLoader = require('../modules/lazy');


var siteGlobal = {
  initReady: function () {
    this.menuLoadLabels();
    this.menuInit(); 
    this.modelsMenu(); 
    this.noClick();
    this.inputWrap();
  },
  initLoad: function () {
    this.menuLabels();
    this.customSelect();
    this.lazyLoad();
    this.linkAll();
    //this.sidebarForm();
    this.langsMenu();
    this.headerChat();
    this.assNums();
    this.privInputWrap();
    this.popupShowTel();
  },
  initResize: function(){
  },
  initResizeEnd: function(){
  },
  initScroll: function(){
    this.headerClass();
  },
  onScroll: function(){
  },
  noClick: function(){
    $('.no-click > a').on('click',function(){
      event.preventDefault();
    })
  },
  lazyLoad: function(){
    imgLoader.lazyInit();
  },
  linkAll: function(){
    $('.linkFrom').each(function() { 
      var elemToLink = $(this).parents('.linkTo');
      var pageLink = $(this).attr('href');
      var linkTarget = (typeof $(this).attr('target')!=='undefined') ?  $(this).attr('target') : '' ;
      if(pageLink !== undefined && pageLink!=='javascript:;') {
        elemToLink.addClass('pointer').click({myLink:pageLink,myTarget:linkTarget},function(e){
          e.preventDefault();
          if(e.data.myTarget==='_blank'){
            window.open(e.data.myLink);
          } else {
            window.location.href = e.data.myLink;
          }
        });
      }
    }); 
  },
  headerClass: function(){
    if ($(window).scrollTop() > 0) {
      $('.header').addClass('scrolled');
    } else {
      $('.header').removeClass('scrolled');
    }
  },
  menuInit: function(){
    $('.header__hamburger, .header .close_label').on('click', function() {
      $('.header, #barba-wrapper').toggleClass('open');
    })

    // submenu modelli desktop/mobile
    if ($(window).outerWidth(true) > 767) {
      $('.modelli-menu > a').on('click', function(event){
        event.preventDefault();
        $(this).toggleClass('active').siblings('.modNav').toggleClass('opened');
      })
    } else {
       $('.modelli-menu > a').on('click', function(event){
        event.preventDefault();
        $(this).toggleClass('active');
        $('.modNav').slideToggle(200);
      })
    }

    // submenu generici
    $( '#main_nav .menu-item-has-children > a' ).on( 'click', function(event) {
        event.preventDefault();
        var _this = $(this),
            thisLi = _this.closest( 'li' ),
            thisSubMenu = thisLi.children( '.sub-menu' );
        
        if ($(window).outerWidth(true) > 767) {

          if( !_this.parent().hasClass('modelli-menu') ){
            $('.modNav').removeClass('opened');
          }
          $('#main_nav .menu-item-has-children > .sub-menu').not( thisSubMenu ).removeClass('opened');
          $('#main_nav .menu-item-has-children > a').not( _this ).removeClass('active');
          $(this).toggleClass('active').siblings('.sub-menu').toggleClass('opened');

        } else {
          $(this).toggleClass('active').siblings('.sub-menu').slideToggle(300);
        }

    } );

  },
  menuLoadLabels: function(){
    $('.header__menuWrap > nav > ul > li > a').each(function(){
      var tit = $(this).attr('title'),
          text = $(this).first().text();

      if ($(window).outerWidth(true) > 767) {
        if (tit!=undefined && tit!="") {
          $(this).append('<span class="menuItemLab">'+tit+'</span>');
        } else {
          $(this).append('<span class="menuItemLab">'+text+'</span>');
        }
      }
    })
  },
  menuLabels: function(){
    $('.header__menuWrap > nav > ul > li > a').each(function(){
      var currWid = $(this).outerWidth(true);

      if ($(window).outerWidth(true) > 767) {
        $(this).on('mouseenter', function(){
          var spanW = $(this).find('.menuItemLab').outerWidth(true);
          if (spanW > currWid) {    
            $(this).animate({
              'width': spanW+'px'
            }, 50);
          }
        })

        $(this).on('mouseleave', function(){
          $(this).animate({
            'width': currWid+'px'
          }, 50);
          $(this).removeClass('active');
        })
      }
    })
  },
  modelsMenu: function(){
    if ($(window).outerWidth(true) > 767) {
      /* Aggiungo classe per far scomparire blocchi immagine/nome */
      $('.modNav__list').on('mouseleave', function(){
        setTimeout(function(){
          $('.modNav__modelWrap').removeClass('mousein').addClass('mouseout');
        }, 350)
      })

      /* Toggle immagini e nomi su stato hover */

      $('.modNav__list a').on('mouseenter', function(){
        var mod = $(this).data('sel');
        $('[data-name="'+mod+'"], [data-image="'+mod+'"]').addClass('active');
        $('.modNav__modelWrap').removeClass('mouseout').addClass('mousein');
      })
      
      $('.modNav__list a').on('mouseleave', function(){
        $('.modNav__img, .modNav__name').removeClass('active');
      })
    }
  },
  // sidebar from disabilito, attiva solo la label come link alla pagina contatti
  /*
  sidebarForm: function(){
    $('.cta__block--parla').on('click', function(){
      event.preventDefault();
      $(this).toggleClass('open');
      $('.cta, .cta__icon--arrow, #chat-application').toggleClass('open');
    })
  },
  */
  langsMenu: function(){
    $('.header__langs').on('click', function(){
      $(this).find('.arrow').toggleClass('rot');
      $(this).find('.lang-sub-menu').fadeToggle(150);
    })
  },
  customSelect: function(){
    $('.wpcf7 select, .numSel').each(function(){
        var $this = $(this), 
          numberOfOptions = $(this).children('option').length,
          classList = $(this).attr('class').split(',');;
      
        $this.addClass('select-hidden'); 
        $this.wrap('<div class="select"></div>');
        $this.after('<div class="select-styled"></div>');

        var $styledSelect = $this.next('div.select-styled');
        $styledSelect.text($this.children('option').eq(0).text());
      
        var $list = $('<ul />', {
            'class': 'select-options ' + classList
        }).insertAfter($styledSelect);
      
        for (var i = 0; i < numberOfOptions; i++) {
            $('<li />', {
                text: $this.children('option').eq(i).text(),
                rel: $this.children('option').eq(i).val()
            }).appendTo($list);
        }
      
        var $listItems = $list.children('li');
      
        $styledSelect.on('click', function(e) {
            e.stopPropagation();
            $(this).toggleClass('active').next('ul.select-options').slideToggle(150);
            $('div.select-styled.active').not(this).each(function(){
                $(this).removeClass('active').next('ul.select-options').hide();
            });
        });
      
        $listItems.on('click', function(e) {
            e.stopPropagation();
            $styledSelect.text($(this).text()).removeClass('active');
            $this.val($(this).attr('rel'));
            $list.hide();
        });

    });
  },
  headerChat: function(){
    $('.cta__formWrap .cta__btn').fadeOut(10);
    $('.cta__formWrap .richiesta .select-options li').on('click', function(){
      var rel = $(this).attr('rel');

      if (rel === 'Assistenza tecnica') {
        $('.cta__formWrap .messaggio, .cta__formWrap .wpcf7-submit, .cta__formWrap .privacy-acceptance').fadeOut(200);
        setTimeout(function(){
          $('.cta__formWrap .cta__btn').fadeIn(200);
        }, 200)
      } else {
        $('.cta__formWrap .cta__btn').fadeOut(200);
        setTimeout(function(){
          $('.cta__formWrap .messaggio, .cta__formWrap .wpcf7-submit, .cta__formWrap .privacy-acceptance').fadeIn(200);
        }, 200)
      }

    })
  },
  inputWrap: function(){    
    $('[name="privacy-acceptance"]').each(function(){
      $(this).after('<span class="check_wrap"></span>');
    })
  },
  privInputWrap: function(){
    $('[name="privacy"]').after('<span class="check_wrap"></span>');
  },
  assNums: function(){
    $('.assIntro__selWrap li').on('click', function(){
      var phone = $(this).attr('rel');
      if (phone) {
        $('.assNum--en').empty().text(phone).attr('href','tel:'+phone);
        $('.assNum--en').css('opacity','1');
      } else {
        $('.assNum--en').css('opacity','0');
      }
    })
  },
  popupShowTel: function(){
    $('#popup-btn').on('click', function(e){
      e.preventDefault();
      $('.popup__telWrap').addClass('show');
    })
  },
  rebuildAllEvents: function(){
    this.initLoad();
  },
};

global.siteGlobal = siteGlobal;

(function () {

  siteGlobal.initReady();

  $( window ).on('load', function() {
    $('body, #preloader').addClass('loaded');
    siteGlobal.initLoad();
  });

  $(window).resize(function(){
    siteGlobal.initResize();
  });


  if(typeof $(window).resizeend === 'function'){
    $(window).resizeend(function () {
      siteGlobal.initResizeEnd();
    });
  } else {
    $(window).resize(function(){
      siteGlobal.initResizeEnd();
    });
  }
  $(window).scroll(function(){
    siteGlobal.initScroll();
  });

  $(window).on('scroll', function(){
    siteGlobal.onScroll();
  })
}());



